/* minireset */
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img,
embed,
iframe,
object,
audio,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
  text-align: left;
}

/* Type */

@font-face {
  font-family: 'Facultad';
  src: url('/fonts/Facultad-Regular.woff2') format('woff2'),
    url('/fonts/Facultad-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Space Mono';
  src: url('/fonts/SpaceMono-Regular.woff2') format('woff2'),
    url('/fonts/SpaceMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Base */

body {
  font-family: 'Facultad', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
}

nav {
  font-family: 'Space Mono', 'Menlo', 'Courier New', Courier, monospace;
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  transition: font-size 0.3s ease-in-out;
}

main {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  border: 0.75rem solid #0a0a0b;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.container {
  /* align-self: flex-start; */
  padding: 1.5rem;
}

a {
  color: #0a0a0b;
  font-size: 1rem;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

li {
  padding-right: 1rem;
}

hr {
  margin-bottom: 1rem;
  border: 0;
  height: 0.25rem;
  width: 8rem;
  background: #0a0a0b;
}

.bg {
  background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    ),
    url('/assets/images/jacquard-loom.jpg') no-repeat 85% 90%/35%;
  width: 100%;
  height: 100vh;
}

/* Media queries */

@media screen and (min-width: 1024px) {
  h1 {
    font-size: 2.5rem;
  }
  .container {
    align-self: center;
    padding: 0 3rem 2rem;
  }
  li {
    display: inline;
  }
  .bg {
    background-size: 100%, 16%;
    background-position: center, 95% 90%;
  }
}
